.js-doringui
{
    display: table-cell;
    display: inline-block;
}

/* Mover */
.js-doringui-mover-block-order
{
    float: left;
    width: 20px;
    height: 8px;
    margin-right: 1px;
}

.js-doringui-mover-block-order:hover
{
    background-color: red;
}

.js-doringui-rate
{
    margin-bottom: -5px;
}

.js-doringui-rate-star
{
    float: left;
    width: 30px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: -12px -76px;
    cursor: pointer;
}

.js-doringui-rate-star-active
{
    background-position: -52px -76px;
}

.js-doringui-rate-star:hover
{
    background-position: -90px -76px;
}

.js-doringui-rate-star-text
{
    text-align: center;
    width: 25px;
    font-size: 12px;
    margin-top: 24px;
}

.js-doringui-rate-star-text-rated
{
    text-align: center;
    width: 25px;
    font-size: 12px;
    margin-top: 5px;
}

.js-doringui-popup
{
    z-index: 1001;
    position: absolute;
    border-radius: 8px;
    background: url('../img/transp.png');
    top: 0px;
    left: 0px;
    margin: 0px;
    padding: 12px;
    box-shadow: 0 0 40px #A0A0A0;
}

.js-doringui-popup-darken
{
    position: fixed; left: 0px; top: 0px; margin: 0px; width: 100%; height: 100%; z-index: -2;
}

.js-doringui-popup-head
{
    display: block;
    background-color: #FF0050;
    color: #EBEBEB;
    font-weight: bold;
    text-align: left;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    padding: 10px;
    border-radius: 3px 3px 0 0;
}

.js-doringui-popup-head-title
{
    padding-left: 5px;
}

.js-doringui-popup-close
{
    display: block;
    float: left;
    margin-top: -6px;
    margin-left: -6px;
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.js-doringui-popup-close:hover
{
    background-position: -28px 0px;
}

.js-doringui-popup-content
{
    display: block;
    color: #000;
    background-color: #fff;
    text-align: left;
    padding: 10px;
    border-top: none;
    border-radius: 0 0 3px 3px;
}

.js-doringui-checked-sign
{
    width: 13px;
    height: 7px;
    background-position: -56px 0px;
}

/* Radiobox */
.js-doringui-radiobox
{
    width: 30px;
    height: 30px;
    border-radius: 8px 8px 8px 8px;
    cursor: pointer;
}

.js-doringui-radiobox-checked
{
    margin-left: 8px;
    margin-top: 11px;
}

/*Checkbox*/
.js-doringui-checkbox
{
    margin-bottom: -3px;
    width: 37px;
    height: 16px;
    border-radius: 8px 8px 8px 8px;
}

.js-doringui-checkbox-switcher
{
    position: absolute;
    display: block;
    width: 14px;
    height: 14px;
    margin-top: 1px;
    border-radius: 8px 8px 8px 8px;
    background-color: #fff;
}

.js-doringui-checkbox-checked-sign
{
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
}

/* Notifications */
.js-doringui-notifications-container
{
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.1);
  /* box-shadow: 0px 0px 4rem rgba(0, 0, 0, 0.4); */
  border-radius: 0 0 0.6rem 0.6rem;
  border-top: 0;
  z-index: 98887;
  min-width: 30%
}

.js-doringui-notifications-header
{
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
  padding: 0.1rem;
  text-align: center;
}

.js-doringui-notifications-body
{
  overflow-y: auto;
  max-height: 600px;
}

.js-doringui-notifications-action-btns
{
  position: absolute;
  padding: 0.6rem;
  right: 0.4rem;
  margin-top: -0.4rem;
}

.js-doringui-notification
{
  margin: 0.6rem;
  padding: 0.8rem;
  border-radius: 0.5rem;
  border-left: 0.22rem solid red;
}

.js-doringui-notification-progressbar
{
  width: 100%;
  border-top: 0.4rem solid rgba(255, 255, 255, 0.2);
}

.js-doringui-notification-success
{
  /* background-color: rgba(0, 255, 0, 0.7); */
  background-color: #56c40d;
  color: #fff;
}

.js-doringui-notification-error
{
  /* background-color: rgba(255, 0, 0, 0.7); */
  background-color: #ef2828;
  color: #fff;
}

.js-doringui-notification-danger
{
  /* background-color: rgba(255, 0, 0, 0.7); */
  background-color: #ef2828;
  color: #fff;
}

.js-doringui-notification-primary
{
  /* background-color: rgba(0, 187, 255, 0.7); */
  background-color: #4875F5;
  color: #fff;
}

.js-doringui-notification-info
{
  /* background-color: rgba(0, 187, 255, 0.7); */
  background-color: #28abed;
  color: #fff;
}

.js-doringui-notification-warning
{
  /* background-color: rgba(255, 150, 0, 0.7); */
  background-color: #e89e0b;
  color: #fff;
}

/* Line height */

/* Mobile */
@media only screen and (max-width: 767px)
{
  .dg-line-height-0 { line-height: normal !important; }
  .dg-line-height-lg { line-height: 3rem !important; }
  .dg-line-height-2x { line-height: 6rem !important; }
  .dg-line-height-3x { line-height: 10rem !important; }
  .dg-line-height-4x { line-height: 14rem !important; }
  .dg-line-height-5x { line-height: 18rem !important; }
  .dg-line-height-6x { line-height: 22rem !important; }
  .dg-line-height-7x { line-height: 26rem !important; }
}

/* Desktop */
@media only screen and (min-width: 768px)
{
  .dg-line-height-md-0 { line-height: normal !important; }
  .dg-line-height-md-lg { line-height: 3rem !important; }
  .dg-line-height-md-2x { line-height: 6rem !important; }
  .dg-line-height-md-3x { line-height: 10rem !important; }
  .dg-line-height-md-4x { line-height: 14rem !important; }
  .dg-line-height-md-5x { line-height: 18rem !important; }
  .dg-line-height-md-6x { line-height: 22rem !important; }
  .dg-line-height-md-7x { line-height: 24rem !important; }
}

/* Paddings */
.dg-p-0 { padding: 0 !important; }
.dg-p-1 { padding: 0.2rem !important; }
.dg-p-2 { padding: 0.6rem !important; }
.dg-p-3 { padding: 1.12rem !important; }
.dg-p-4 { padding: 1.68rem !important; }
.dg-p-5 { padding: 2.12rem !important; }
.dg-pt-0 { padding-top: 0 !important; }
.dg-pt-1 { padding-top: 0.2rem !important; }
.dg-pt-2 { padding-top: 0.6rem !important; }
.dg-pt-3 { padding-top: 1.12rem !important; }
.dg-pt-4 { padding-top: 1.68rem !important; }
.dg-pt-5 { padding-top: 2.12rem !important; }
.dg-pb-0 { padding-bottom: 0 !important; }
.dg-pb-1 { padding-bottom: 0.2rem !important; }
.dg-pb-2 { padding-bottom: 0.6rem !important; }
.dg-pb-3 { padding-bottom: 1.12rem !important; }
.dg-pb-4 { padding-bottom: 1.68rem !important; }
.dg-pb-5 { padding-bottom: 2.12rem !important; }
.dg-pl-0 { padding-left: 0 !important; }
.dg-pl-1 { padding-left: 0.2rem !important; }
.dg-pl-2 { padding-left: 0.6rem !important; }
.dg-pl-3 { padding-left: 1.12rem !important; }
.dg-pl-4 { padding-left: 1.68rem !important; }
.dg-pl-5 { padding-left: 2.12rem !important; }
.dg-pr-0 { padding-right: 0 !important; }
.dg-pr-1 { padding-right: 0.2rem !important; }
.dg-pr-2 { padding-right: 0.6rem !important; }
.dg-pr-3 { padding-right: 1.12rem !important; }
.dg-pr-4 { padding-right: 1.68rem !important; }
.dg-pr-5 { padding-right: 2.12rem !important; }
.dg-px-0 { padding-left: 0 !important; padding-right: 0 !important; }
.dg-px-1 { padding-left: 0.2rem !important; padding-right: 0.2rem !important; }
.dg-px-2 { padding-left: 0.6rem !important; padding-right: 0.6rem !important; }
.dg-px-3 { padding-left: 1.12rem !important; padding-right: 1.12rem !important; }
.dg-px-4 { padding-left: 1.68rem !important; padding-right: 1.68rem !important; }
.dg-px-5 { padding-left: 2.12rem !important; padding-right: 2.12rem !important; }
.dg-py-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
.dg-py-1 { padding-top: 0.2rem !important; padding-bottom: 0.2rem !important; }
.dg-py-2 { padding-top: 0.6rem !important; padding-bottom: 0.6rem !important; }
.dg-py-3 { padding-top: 1.12rem !important; padding-bottom: 1.12rem !important; }
.dg-py-4 { padding-top: 1.68rem !important; padding-bottom: 1.68rem !important; }
.dg-py-5 { padding-top: 2.12rem !important; padding-bottom: 2.12rem !important; }

/* Margins */
.dg-m-0 { margin: 0 !important; }
.dg-m-1 { margin: 0.2rem !important; }
.dg-m-2 { margin: 0.6rem !important; }
.dg-m-3 { margin: 1.12rem !important; }
.dg-m-4 { margin: 1.68rem !important; }
.dg-m-5 { margin: 2.12rem !important; }
.dg-mt-0 { margin-top: 0 !important; }
.dg-mt-1 { margin-top: 0.2rem !important; }
.dg-mt-2 { margin-top: 0.6rem !important; }
.dg-mt-3 { margin-top: 1.12rem !important; }
.dg-mt-4 { margin-top: 1.68rem !important; }
.dg-mt-5 { margin-top: 2.12rem !important; }
.dg-mb-0 { margin-bottom: 0 !important; }
.dg-mb-1 { margin-bottom: 0.2rem !important; }
.dg-mb-2 { margin-bottom: 0.6rem !important; }
.dg-mb-3 { margin-bottom: 1.12rem !important; }
.dg-mb-4 { margin-bottom: 1.68rem !important; }
.dg-mb-5 { margin-bottom: 2.12rem !important; }
.dg-ml-0 { margin-left: 0 !important; }
.dg-ml-1 { margin-left: 0.2rem !important; }
.dg-ml-2 { margin-left: 0.6rem !important; }
.dg-ml-3 { margin-left: 1.12rem !important; }
.dg-ml-4 { margin-left: 1.68rem !important; }
.dg-ml-5 { margin-left: 2.12rem !important; }
.dg-mr-0 { margin-right: 0 !important; }
.dg-mr-1 { margin-right: 0.2rem !important; }
.dg-mr-2 { margin-right: 0.6rem !important; }
.dg-mr-3 { margin-right: 1.12rem !important; }
.dg-mr-4 { margin-right: 1.68rem !important; }
.dg-mr-5 { margin-right: 2.12rem !important; }
.dg-mx-0 { margin-left: 0 !important; margin-right: 0 !important; }
.dg-mx-1 { margin-left: 0.2rem !important; margin-right: 0.2rem !important; }
.dg-mx-2 { margin-left: 0.6rem !important; margin-right: 0.6rem !important; }
.dg-mx-3 { margin-left: 1.12rem !important; margin-right: 1.12rem !important; }
.dg-mx-4 { margin-left: 1.68rem !important; margin-right: 1.68rem !important; }
.dg-mx-5 { margin-left: 2.12rem !important; margin-right: 2.12rem !important; }
.dg-my-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
.dg-my-1 { margin-top: 0.2rem !important; margin-bottom: 0.2rem !important; }
.dg-my-2 { margin-top: 0.6rem !important; margin-bottom: 0.6rem !important; }
.dg-my-3 { margin-top: 1.12rem !important; margin-bottom: 1.12rem !important; }
.dg-my-4 { margin-top: 1.68rem !important; margin-bottom: 1.68rem !important; }
.dg-my-5 { margin-top: 2.12rem !important; margin-bottom: 2.12rem !important; }

/* Buttons */
.dg-btn
{
  display: inline-block;
  padding: 0.5rem;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  border-radius: 0.4rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.dg-btn-primary
{
  color: #CFFAFF;
  border-color: #65A6F7;
  background-color: #4875F5;
  transition: background-color 0.4s;
}

.dg-btn-primary:hover
{
  transition: background-color 0.2s;
  background-color: #3A5FC7;
}

.dg-btn-primary:active
{
  transition: background-color 0.2s;
  background-color: #3150A8;
}

.dg-btn-success
{
  color: #CFFAFF;
  border-color: #65A6F7;
  background-color: #56c40d;
  transition: background-color 0.4s;
}

.dg-btn-success:hover
{
  transition: background-color 0.2s;
  background-color: #6aa841;
}

.dg-btn-success:active
{
  transition: background-color 0.2s;
  background-color: #328718;
}

/* btn info */
.dg-btn-info
{
  transition: background-color 0.2s;
  background-color: #28abed;
}

.dg-btn-info:hover
{
  transition: background-color 0.2s;
  background-color: #269bd6;
}

.dg-btn-info:active
{
  transition: background-color 0.2s;
  background-color: #2486b7;
}

/* btn danger */
.dg-btn-danger
{
  transition: background-color 0.2s;
  background-color: #ef2828;
}

.dg-btn-danger:hover
{
  transition: background-color 0.2s;
  background-color: #d32323;
}

.dg-btn-danger:active
{
  transition: background-color 0.2s;
  background-color: #b21e1e;
}

/* btn error */
.dg-btn-error
{
  transition: background-color 0.2s;
  background-color: #ef2828;
}

.dg-btn-error:hover
{
  transition: background-color 0.2s;
  background-color: #d32323;
}

.dg-btn-error:active
{
  transition: background-color 0.2s;
  background-color: #b21e1e;
}

/* btn warning */
.dg-btn-warning
{
  transition: background-color 0.2s;
  background-color: #e89e0b;
}

.dg-btn-warning:hover
{
  transition: background-color 0.2s;
  background-color: #db9915;
}

.dg-btn-warning:active
{
  transition: background-color 0.2s;
  background-color: #c48a17;
}

/*Increaser*/
.js-doringui-incr-arrows
{
    display: block;
    width: 21px;
    height: 10px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
}

.js-doringui-incr-arrows-up
{
    background-position: -90px 0px;
}

.js-doringui-incr-arrows-down
{
    background-position: -69px 0px;
}

.js-doringui-limit
{
    position: absolute;
    color: #fff;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 10px;
    margin-top: -5px;
    border: 1px solid grey;
    display: none;
    box-shadow: 0px 1px 4px red;
}

.js-doringui-arrow-corners
{
    display: block;
    width: 22px;
    height: 20px;
}

.js-doringui-corner-right-bottom
{
    background-position: -1px -28px;
}

.js-doringui-chevrons
{
    display: block;
    width: 22px;
    height: 11px;
}

.js-doringui-chevron-up
{
    background-position: -91px -11px;
}

.js-doringui-chevron-down
{
    background-position: -69px -11px;
}

.transp, #transp
{
    background: url('../img/transp.png');
    color: #fff;
}

.transp_hard, #transp_hard
{
    background: url('../img/transp_hard.png');
    color: #fff;
}

.transp_really_hard, #transp_really_hard
{
    background: url('../img/transp_really_hard.png');
    color: #fff;
}
